import { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';

import { TOOLTIP_DELAY_TIME, TOOLTIP_HIDE_TIME } from '@/config/common';

const TooltipWithRef = ({ children, text }) => {
  const targetRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  useEffect(() => {
    if (tooltipOpen && targetRef.current === null) {
      setTimeout(() => {
        setTooltipOpen(false);
      }, TOOLTIP_HIDE_TIME);
    }
  }, []);

  return (
    <div>
      <div
        onBlur={() => {
          targetRef.current = null;
        }}
        ref={targetRef}
      >
        {children}
      </div>
      <Tooltip
        autohide={true}
        delay={{
          hide: TOOLTIP_HIDE_TIME,
          show: TOOLTIP_DELAY_TIME
        }}
        innerClassName='bg-black text-white text-xs p-1.5 px-2 rounded z-50'
        isOpen={tooltipOpen}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-135, 0]
            }
          }
        ]}
        placement='bottom'
        target={targetRef}
        toggle={toggleTooltip}
      >
        {text}
      </Tooltip>
    </div>
  );
};

export default TooltipWithRef;
